<template>
  <div class="privacy-policy">
    <h1>Política de Privacidade - Aplicativos para Celular da UpCities</h1>
    <p>
      Confira nossa política de privacidade para os aplicativos de celular
      publicados nas lojas de aplicativos PlayStore e Apple Store
    </p>
    <div class="wrap">
      <h1>01 – Dados e Informações Coletados</h1>
      <p>
        As informações coletadas pelos nossos apps incluirão todas ou apenas
        algumas das seguintes, dependendo de quais e como você optar por usar os
        apps.
      </p>
      <p>
        a) Geolocalização e Endereço IP - Informações detalhadas sobre a
        geolocalização, sob a forma de sinais GPS, endereço IP e outras
        informações relacionadas enviadas pelo dispositivo móvel no qual o
        aplicativo está instalado e ativado;
      </p>
      <p>
        b) Detalhes do Aparelho – Informações de status de bateria, modelo do
        aparelho, sistema operacional e versão utilizados, apps instalados e
        tipo de conexão utilizada (rede móvel de dados ou wi-fi);
      </p>
      <p>
        c) Device ID – Identificador único que cada aparelho com sistema
        operacional possui;
      </p>
      <p>
        d) Dados de acesso – Para fins exclusivos de permitir o acesso aos
        serviços relacionados do Google/Apple, os apps poderão solicitar dados
        de acesso à conta vinculada ao app.
      </p>
      <p>
        e) Informações de Áudio -  O aplicativo utiliza uma tecnologia que realiza
        a conversão de áudio em texto, proporcionando uma experiência aprimorada ao
        usuário. Nesse processo, apenas o texto resultante é armazenado em nosso banco de dados,
        assegurando a confidencialidade e segurança dos dados dos usuários. Não há qualquer registro,
        compartilhamento ou utilização de gravações de áudio.
      </p>
      <h1>02 – Banco de Dados do Aplicativo</h1>
      <p>
        As informações inseridas por você usuário através e para uso exclusivo
        nos nossos aplicativos são armazenadas em banco de dados local no seu
        aparelho e após a desinstalação do aplicativo as informações são
        apagadas. Contudo, ressaltamos que sua conta do Google ou Apple pode
        possuir Configurações de backup automático de alguns desses dados por
        você informados nos apps. Se porventura você não desejar realizar o
        backup automático desses dados, você poderá desativar essa
        funcionalidade manualmente nas configurações do seu dispositivo ou
        acessando suas configurações de conta.
      </p>

      <h1>03 – Uso dos Dados e Informações Coletados</h1>
      <p>
        03.1 – Uso nos Aplicativos Alguns dados coletados são exclusivamente
        utilizados para manter o correto funcionamento dos aplicativos, como por
        exemplo o identificador único do dispositivo, item fundamental para a
        comunicação do seu dispositivo com nossos servidores, onde através do
        mesmo seu dispositivo poderá ser identificado unicamente para que as
        informações possam ser enviadas aos nossos servidores e retornadas
        corretamente para o seu aparelho e aplicativo.
      </p>
      <p>
        03.2 – Outros Alguns dos dados fornecidos já citados nessa política
        poderão também vir a ser utilizados exclusivamente para auditoria e
        análise interna, para nos ajudar a desenvolver, melhorar nossos serviços
        e conteúdos disponibilizados.
      </p>
      <h1>04 – Permissões de Acesso</h1>
      <p>
        A fim de reforçar para o usuário quais permissões de acesso no seu
        aparelho serão utilizadas, o sistema operacional irá solicitar sua
        aceitação manual e individual de algumas permissões, como a coleta da
        localização. Esclarecemos que caso você não permita esse acesso algumas
        funcionalidades do aplicativo poderão ficar inutilizadas.
      </p>
      <h1>05 – Validade</h1>
      <p>
        Os termos e informações aqui descritos são válidos somente para os
        nossos aplicativos baixados e instalados exclusivamente através da
        Playstore ou Apple Store. Não podemos garantir a procedência de qualquer
        aplicativo que porventura venha a ser disponibilizado ou instalado de
        outra maneira.
      </p>
      <h1>06 - Dúvidas sobre Privacidade</h1>
      <p>
        06.1 – Se você tiver algum questionamento ou dúvida com relação à
        Política de Privacidade dos nossos aplicativos ou qualquer prática
        descrita aqui, poderá entrar em contato conosco através do e-mail
        info@upcities.app onde teremos grande satisfação em lhe atender e
        prestar esclarecimentos o mais breve possível.
      </p>
      <p>
        06.2 – Se fizermos alguma alteração na política a mesma será
        imediatamente atualizada no link oficial e visível através de local
        próprio e adequado disponibilizado em nossa página na Loja de
        Aplicativos, estando disponível para sua consulta sempre que achar
        necessário.
      </p>
      <h1>07 – Concordância</h1>
      <p>
        Caso não queira que os dados sejam coletados mas mesmo assim deseja
        continuar usando nossas aplicações, você deve alterar as Configurações
        de privacidade e permissões do seu aparelho. AO UTILIZAR OS APLICATIVOS
        VOCÊ CONCORDA EXPRESSA E INTEGRALMENTE COM ESTA POLÍTICA DE PRIVACIDADE,
        COMO CONDIÇÃO INDISPENSÁVEL À UTILIZAÇÃO DE SUAS FUNCIONALIDADES E
        SERVIÇOS e em ficar vinculado por qualquer alteração futura que possa
        ser realizada nos termos dessa Política de Privacidade. Se você NÃO
        CONCORDAR com algum dos termos você deve evitar qualquer uso dos nosso
        aplicativos.
      </p>
      <h1>DATA DA ÚLTIMA ATUALIZAÇÃO DESSA POLÍTICA DE PRIVACIDADE</h1>
      <p class="font-gray">
        02 de Maio de 2023
      </p>
      <p>
        Caso tenha dúvidas ou queira entrar em contato conosco poderá será
        realizado através do e-mail info@upcities.app
      </p>
      <p class="text-center">
        Copyright © upcities.app
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  title: 'UpCities - Política de Privacidade'
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  max-width: 100vw;
  padding: 1rem;

  .wrap {
    padding: 0 2rem;
  }

  h1 {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .font-gray {
    color: $gray-500;
  }

  .text-center {
    text-align: center;
  }
}
</style>
